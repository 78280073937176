<template>
  <div>
    <div class="field" v-if="item.miaftrentrydate">
      <p class="has-text-grey">MIAFTR entry date</p>
      <p>{{ formatDate(item.miaftrentrydate) }}</p>
    </div>
    <div class="field" v-if="item.lossDate">
      <p class="has-text-grey">Loss date</p>
      <p>{{ formatDate(item.lossDate) }}</p>
    </div>
    <div class="field" v-if="item.lossType">
      <p class="has-text-grey">Loss type</p>
      <p>{{ item.lossType }}</p>
    </div>
    <div class="field" v-if="item.insurerBranch">
      <p class="has-text-grey">Insurer branch</p>
      <p>{{ item.insurerBranch }}</p>
    </div>
    <div class="field" v-if="item.insurerName">
      <p class="has-text-grey">Insurer name</p>
      <p>{{ item.insurerName }}</p>
    </div>
    <div class="field" v-if="item.contactNumber">
      <p class="has-text-grey">Contact number</p>
      <p>{{ item.contactNumber }}</p>
    </div>
    <div class="field" v-if="item.claimNumber">
      <p class="has-text-grey">Claim number</p>
      <p>{{ item.claimNumber }}</p>
    </div>
    <div class="field" v-if="item.make">
      <p class="has-text-grey">Make and model</p>
      <p>{{ item.make }} {{ item.model }}</p>
    </div>
    <div class="field" v-if="item.theftIndicatorLiteral">
      <p class="has-text-grey">Theft indicator (MIAFTR)</p>
      <p>{{ item.theftIndicatorLiteral }}</p>
    </div>
    <div class="field" v-if="item.status">
      <p class="has-text-grey">Status</p>
      <p>{{ item.status }}</p>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/utils/date.utils.ts'
export default {
  name: 'AutoCheckConditionItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    formatDate
  }
}
</script>
